require("./../../global/js/global-entry")
require("./main")

import "./index.scss"
import gsap from "gsap"
import MotionPathPlugin from "gsap/MotionPathPlugin"
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
/**
 * outImageUrl - change image url in svg (if image save with link)
 * needContent - return content to js file
 * par - preserveAspectRatio attribute for svg
 * class - class for svg <svg class=""></svg>
 * ./img/test_svg/test.svg - path to svg in src
 */
let svgContent = require("!!svg-anim-loader?outImageUrl=./assets/img/&needContent=false&par=''&class=''!./img/service-img.svg")
require("!!svg-anim-loader?outImageUrl=./assets/img/&needContent=false&par=''&class=''!./img/heroImg.svg")
// require("!!svg-anim-loader?outImageUrl=./assets/img/&needContent=false&par=''&class=''!./img/about-main-image.svg")
// console.log(svgContent);

// ================= SWIPER ==========
document.addEventListener("DOMContentLoaded", function () {
    gsap.set('dfrnc', { opacity: 1 })
    console.log('indexJS');

    gsap.set('#treeLeft >*', { x: -window.innerHeight * 10 })
    gsap.set('#fallingTree', { x: -window.innerHeight * 10 })
    gsap.set('#treeLeftBg', { x: -window.innerHeight * 10 })
    gsap.set('#treeRight >*', { x: window.innerHeight * 10 })
    gsap.set('#treeRightBg', { x: window.innerHeight * 10 })
    gsap.set('#bgBottom', { y: window.innerHeight * 10 })
    gsap.set('#skyMove', { y: window.innerHeight * 10 })
    gsap.set('#skyMoveBg >:nth-child(1)', { y: window.innerHeight * 10 })
    gsap.set('#skyMoveBg >:nth-child(2)', { y: window.innerHeight * 10 })
    gsap.set('#fallingTreeMove', { transformOrigin: "50% 50%" })
    gsap.set('#fallingTreeMove1', { transformOrigin: "50% 50%" })

    for (let i = 1; i <= 11; i++) {
        gsap.set(`#co2G${i}`, { y: 0 }, "<");
        gsap.to(`#co2G${i}`, {
            duration: 1,
            transformOrigin: '50% 50%',
            scale: 1.1,
            repeat: -1,
            yoyo: true,
            rotation: 0.1,
            ease: 'sine.inOut'
        }, ">");
    }
    function bubble(params) {

        gsap.to('#co2G1', { repeat: -1, scale: 0.7, delay: 0, motionPath: { path: '#counter1', align: '#counter1', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G2', { repeat: -1, scale: 0.7, delay: 1, motionPath: { path: '#counter2', align: '#counter2', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G3', { repeat: -1, scale: 0.7, delay: 1.5, motionPath: { path: '#counter3', align: '#counter3', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G4', { repeat: -1, scale: 0.7, delay: 2, motionPath: { path: '#counter4', align: '#counter4', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G5', { repeat: -1, scale: 0.7, delay: 2.5, motionPath: { path: '#counter5', align: '#counter5', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });

        gsap.to('#co2G6', { repeat: -1, scale: 0.7, delay: 0, motionPath: { path: '#counter6', align: '#counter6', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G7', { repeat: -1, scale: 0.7, delay: 1, motionPath: { path: '#counter7', align: '#counter7', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G8', { repeat: -1, scale: 0.7, delay: 1.5, motionPath: { path: '#counter8', align: '#counter8', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G9', { repeat: -1, scale: 0.7, delay: 2, motionPath: { path: '#counter9', align: '#counter9', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G10', { repeat: -1, scale: 0.7, delay: 2.5, motionPath: { path: '#counter10', align: '#counter10', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
        gsap.to('#co2G11', { repeat: -1, scale: 0.7, delay: 0, motionPath: { path: '#counter11', align: '#counter11', alignOrigin: [0.5, 0.5], autoRotate: true }, duration: 7, ease: "sine.inOut" });
    }

    gsap.set('#co2>*', { opacity: 0 })
    gsap.set('.swiper-wrapper2 .swiper-slide2 >:nth-child(1)', { y: window.innerHeight * 10 })
    gsap.set('.swiper-wrapper2  .swiper-slide2 >:nth-child(2)', { y: window.innerHeight * 10 })
    gsap.set('.swiper-wrapper2  .swiper-slide2 >:nth-child(3)', { y: window.innerHeight * 10 })
    gsap.set('.swiper-wrapper2  .swiper-slide2 >:nth-child(4)', { y: window.innerHeight * 10 })
    gsap.set('.swiper-wrapper2  .swiper-slide2 >:nth-child(5)', { y: window.innerHeight * 10 })
    gsap.set('.swiper-wrapper2  .swiper-slide2 >:nth-child(6)', { y: window.innerHeight * 10 })
    gsap.set('#banner2', { y: window.innerHeight * 10 })
    gsap.set('#home1', { scale: 0, transformOrigin: '50% 50%' })
    gsap.set('#home2', { scale: 0, transformOrigin: '50% 50%' })
    gsap.set('#home3', { scale: 0, transformOrigin: '50% 50%' })
    gsap.set('#banner3Elem1', { x: window.innerHeight * 10 })
    gsap.set('#banner3Elem2', { x: -window.innerHeight * 10 })
    gsap.set('#banner3Elem3', { x: window.innerHeight * 10 })
    gsap.set('#banner3Elem4', { y: window.innerHeight * 10 })
    gsap.set('#banner3Elem5', { y: window.innerHeight * 10 })
    gsap.set('#banner3Elem6', { y: window.innerHeight * 10 })
    gsap.set('#banner3Elem7', { y: window.innerHeight * 10 })
    gsap.set('#banner3Elem8', { y: window.innerHeight * 10 })
    gsap.set('#banner3Elem9', { y: window.innerHeight * 10 })
    gsap.set('#banner3ElemSky', { y: window.innerHeight * 10 })
    gsap.set('#cunter01', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter02', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter03', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter04', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter05', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter06', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter07', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter08', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#cunter09', { opacity: 0, strokeDasharray: 51, strokeDashoffset: 51 })
    gsap.set('#carCircle01', { transformOrigin: "50% 50%" })
    gsap.set('#carCircle02', { transformOrigin: "50% 50%" })
    gsap.set('#banner2', { transformOrigin: "50% 50%" })
    console.log('#home1');
    gsap.to('#cunter01', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter02', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter03', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter04', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter05', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter06', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter07', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter08', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#cunter09', { strokeDashoffset: 0, repeat: -1, duration: 1, repeatDelay: 0.5, ease: 'sine.inOut' })
    gsap.to('#home1Move', { duration: 1, repeat: -1, yoyo: true, scale: 1.05, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#home2Move', { duration: 1, repeat: -1, yoyo: true, scale: 1.05, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#home3Move', { duration: 1, repeat: -1, yoyo: true, scale: 1.05, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#sky01', { duration: 18, repeat: -1, yoyo: true, x: -250, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#sky02', { duration: 18, repeat: -1, yoyo: true, x: -250, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#sky03', { duration: 18, repeat: -1, yoyo: true, x: -250, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#sky04', { duration: 18, repeat: -1, yoyo: true, x: -250, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#sky05', { duration: 18, repeat: -1, yoyo: true, x: -250, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#sky06', { duration: 18, repeat: -1, yoyo: true, x: 250, transformOrigin: "50% 50%", ease: 'sine.inOut' })
    gsap.to('#car01', { duration: 0.7, repeat: -1, yoyo: true, scaleY: 1.02, transformOrigin: "0% 100%", ease: 'sine.inOut' })
    let tlPeople = gsap.timeline();
    function peopleMove(params) {
        tlPeople.to('#peoples >*', {
            duration: 1,
            repeat: -1,
            yoyo: true,
            rotation: 10,
            stagger: { from: 'random', each: 0.02 },
            transformOrigin: "50% 50%",
            ease: 'sine.inOut'
        });
    }
    peopleMove()
    tlPeople.play();
    function hideBanner1(params) {
        gsap.to('#treeLeft >*', { x: -window.innerHeight * 10, duration: 1, })
        gsap.to('#fallingTree', { x: -window.innerHeight * 10, duration: 1, })
        gsap.to('#treeLeftBg', { x: -window.innerHeight * 10, duration: 1, })
        gsap.to('#treeRight >*', { x: window.innerHeight * 10, duration: 1, })
        gsap.to('#treeRightBg', { x: window.innerHeight * 10, duration: 1, })
        gsap.to('#skyMove', { y: window.innerHeight * 10, duration: 1, })
        gsap.to('#skyMoveBg >:nth-child(1)', { y: window.innerHeight * 10, duration: 1, })
        gsap.to('#skyMoveBg >:nth-child(2)', { y: window.innerHeight * 10, duration: 1, })
        gsap.to('#co2>*', { opacity: 0, duration: 0.2, })
        gsap.to('#bgBottom', { y: window.innerHeight * 10, duration: 1, }, ">")

    }

    function banner2(params) {

        gsap.set('#bgBottom', { duration: 1, y: 0 })
        gsap.set('.dots >:nth-child(1)', { duration: .2, opacity: 1, })
        gsap.set('#treeLeft >*', { duration: 1, x: 0, })
        gsap.set('#fallingTree', { duration: 1, x: 0, })
        gsap.set('#treeRight >*', { duration: 1, x: 0, })
        gsap.set('#treeRightBg', { duration: 1, x: 0, })
        gsap.set('#treeLeftBg', { duration: 1, x: 0, })
        gsap.set('#skyMoveBg >:nth-child(1)', { duration: 1, y: 0, })
        gsap.set('#skyMoveBg >:nth-child(2)', { duration: 1, y: 0, })
        gsap.set('#skyMove', { duration: 1, y: 0, })
        gsap.set('#fallingTreeMove', {
            duration: 1, rotation: -90,
        })
        gsap.set('#fallingTreeMove1', {
            duration: 1, x: -100, y: -20,
        })
    }

    let swiper
    let animBanner1
    let animArr = [
        'slide1',
        'slide2',
        'slide3',
        'slide4',
        'slide5',
        'slide6',

    ]
    let tweening = false;

    let activeIndex = 0
    let prevIndex = 0
    // let btnDots = [
    //     { target: '.dot1', index: 0 },
    //     { target: '.dot2', index: 1 },
    //     { target: '.dot3', index: 2 },
    //     { target: '.dot4', index: 3 },
    //     { target: '.dot5', index: 4 },
    // ];
    // let btnDots = Array.from({ length: animArr.length }, (_, i) => ({
    //     target: `.menu${i + 1}`,
    //     index2: i + 1,
    // }));
    let btnDots = Array.from({ length: animArr.length }, (_, i) => ({
        target: `.dot${i + 1}`,
        index2: i + 1,
    }));
    btnDots.forEach((elem) => {
        const targetElement = document.querySelector(elem.target);
        if (targetElement) {
            targetElement.addEventListener('click', function () {
                setTimeout(() => {
                    animChangeClickDots(elem.index2);
                    setTimeout(() => {
                        if (elem.target === '.dot2') {
                            banner2()
                        }
                        if (elem.target === '.dot3') {
                            tlPeople.play();
                            hideBanner1()

                        }
                        if (elem.target === '.dot4') {
                            hideBanner1()
                        }
                        if (elem.target === '.dot5') {
                            hideBanner1()
                        }
                        if (elem.target === '.dot6') {
                            hideBanner1()
                        }
                        animBanner1.play()
                    }, 100);
                }, 700);
            });
        }
    });

    function animChangeClickDots(index2) {
        if (!tweening) {
            tweening = true;
            prevIndex = activeIndex;
            activeIndex = index2 - 1;

            animBanner1.tweenTo(animArr[activeIndex], {
                onStart: () => {
                    setTimeout(function () {
                        tweening = false;
                    }, 500);
                },
                duration: 0.15,
                ease: 'sine.InOut',
            });
            // console.log(animArr[activeIndex]);
        }
    }
    setTimeout(() => {
        // swiper = new Swiper(".mySwiper", {
        //     pagination: {
        //         el: ".swiper-pagination",
        //         clickable: true,
        //     },
        //     effect: "fade",
        //     loop: false,
        //     loop: true,
        //     speed: 5000,
        //     on: {
        //         slideChange: function () {
        //             if (this.activeIndex === 2) {
        //                 hideBanner1()
        //             }
        //         }
        //     }
        // });
        // function test(params) {

        // }
        animBanner1 = gsap.timeline({ repeat: -1 })
            .addLabel('slide1')
            .to('#bgBottom', { duration: 1, y: 0, ease: 'back.inOut(0.5)', }, "<")
            .to('.dots >:nth-child(1)', { duration: .2, opacity: 1, }, "<")
            .to('#treeLeft >*', { duration: 1, stagger: { each: 0.1, from: 'random' }, x: 0, ease: 'back.inOut(.5)' }, "<+0.2")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(1)', {
                duration: 1, y: 0, ease: 'back.inOut(.5)'
            }, "<")
            .to('#fallingTree', { duration: 1, stagger: { each: 0.1, from: 'random' }, x: 0, ease: 'back.inOut(.5)' }, "<+0.2")
            .to('#treeRight >*', { duration: 1, stagger: { each: 0.1, from: 'random' }, x: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#treeRightBg', { duration: 1, x: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#treeLeftBg', { duration: 1, x: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#skyMoveBg >:nth-child(1)', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#skyMoveBg >:nth-child(2)', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#skyMove', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.1")
            .to('#fallingTreeMove', {
                duration: 1, rotation: -90, ease: 'back.inOut(.5)', onComplete() { bubble() }
            }, ">+0.5")
            .to('#fallingTreeMove1', {
                duration: 1, x: -100, y: -20, ease: 'back.inOut(.5)'
            }, "<")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(1)', {
                duration: 1, y: window.innerHeight * 10, ease: 'none'
            }, ">+1.5")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(2)', {
                duration: 1, y: 0, ease: 'none'
            }, "<-0.5")
            .addLabel('slide2')
            .to('.dots >:nth-child(2)', { duration: .2, opacity: 1, }, ">")
            .to('#co2>*', {
                duration: 1, opacity: 1, ease: 'none',

            }, "<")
            .to('#co2>*', {
                duration: 1, ease: 'none', onComplete() {
                    // swiper.slideNext(1);
                    hideBanner1()

                }

            }, ">+3")

            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(2)', { duration: 1, y: window.innerHeight * 10, ease: 'none' }, ">")
            .to('#banner2', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(3)', { duration: 1, y: 0, ease: 'none' }, "<")
            .addLabel('slide3')
            .to('.dots >:nth-child(3)', {
                duration: .2, opacity: 1
            }, "<")

            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(3)', { duration: 1, y: window.innerHeight * 10, ease: 'none' }, ">+7")

            .to('#home1', {
                scale: 1, onComplete() {
                    tlPeople.pause();
                }
            }, "<")
            .to('#home2', { scale: 1 }, "<")
            .to('#home3', { scale: 1 }, "<")
            .to('#cunter01', { opacity: 1 }, ">+0.8")
            .to('#cunter02', { opacity: 1 }, "<")
            .to('#cunter03', { opacity: 1 }, "<")
            .to('#cunter04', { opacity: 1 }, "<")
            .to('#cunter05', { opacity: 1 }, "<")
            .to('#cunter06', { opacity: 1 }, "<")
            .to('#cunter07', { opacity: 1 }, "<")
            .to('#cunter08', { opacity: 1 }, "<")
            .to('#cunter09', { opacity: 1 }, "<")
            .addLabel('slide4')
            .to('.dots >:nth-child(4)', { duration: .2, opacity: 1, }, "<")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(4)', { duration: 1, y: 0, ease: 'none' }, "<-0.5")

            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(4)', { duration: 1, y: window.innerHeight * 10, ease: 'none' }, ">+6")

            .to('#banner2', { duration: 1, y: window.innerHeight * 10, ease: 'back.inOut(.5)' }, "<")

            .to('#fallingTreeMove', { duration: 1, rotation: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#fallingTreeMove1', { duration: 1, x: 0, y: 0, ease: 'back.inOut(.5)' }, "<")

            .to('#banner3Elem4', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem5', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .to('#banner3Elem6', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .to('#banner3Elem7', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .to('#banner3Elem8', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .to('#banner3Elem9', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .to('#banner3ElemSky', { duration: 1, y: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .addLabel('slide5')
            .to('.dots >:nth-child(5)', { duration: .2, opacity: 1, }, "<")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(5)', { duration: 1, y: 0, ease: 'none' }, "<-0.5")

            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(5)', { duration: 1, y: window.innerHeight * 10, ease: 'none' }, ">+5")

            .to('#banner3Elem6', { duration: 1, y: window.innerHeight * 10, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem1', { duration: 1, x: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem3', { duration: 3, x: 0, ease: 'back.inOut(.5)' }, "<")
            .to('#carCircle01', { duration: 3, rotation: -180, ease: 'back.inOut(.5)' }, "<")
            .to('#carCircle02', { duration: 3, rotation: -180, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem2', { duration: 1, x: 0, ease: 'back.inOut(.5)' }, "<+0.05")
            .addLabel('slide6')
            .to('.dots >:nth-child(6)', { duration: .2, opacity: 1, }, "<")
            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(6)', {
                duration: 1, y: 0, ease: 'none'
            }, "<-0.5")

            .to('.swiper-wrapper2 .swiper-slide2 >:nth-child(6)', { duration: 1, y: window.innerHeight * 10, ease: 'none' }, ">+7")

            .to('#banner3Elem1', { duration: 1, x: window.innerHeight * 1.5, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem2', { duration: 1, x: -window.innerHeight * 1.5, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem3', { duration: 1, x: window.innerHeight * 1.5, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem4', { duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)' }, ">-0.5")
            .to('#banner3Elem5', { duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem6', { duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem7', { duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem8', { duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3Elem9', { duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)' }, "<")
            .to('#banner3ElemSky', {
                duration: 1, y: window.innerHeight, ease: 'back.inOut(.5)', onComplete() {
                    tlPeople.play();
                }
            }, "<")


        // GSDevTools.create({ animation: animBanner1 });
    }, 10);

    // Transition Sections
    let animBottom = gsap.utils.toArray('.anim-bottom')
    animBottom.forEach(item => {
        gsap.fromTo(item, { duration: 1, y: 100, autoAlpha: 0, ease: 'sine.inOut' }, {
            duration: 1, autoAlpha: 1,
            y: 0, ease: 'sine.inOut',
            scrollTrigger: {
                trigger: item,
                start: '50% 100%',
                end: '50% 100%',
                scrub: 2,
                markers: false,
            }
        })
    })
    let animLeft = gsap.utils.toArray('.anim-left')
    animLeft.forEach(item => {
        gsap.fromTo(item, { duration: 1, x: -100, y: 100, autoAlpha: 0, ease: 'sine.inOut' }, {
            duration: 1, x: 0, autoAlpha: 1,
            y: 0, ease: 'sine.inOut',
            scrollTrigger: {
                trigger: item,
                start: '50% 100%',
                end: '50% 100%',
                scrub: 2,
                markers: false,
            }
        })
    })
    let animRight = gsap.utils.toArray('.anim-right')
    animRight.forEach(item => {
        gsap.fromTo(item, { duration: 1, x: 100, y: 100, autoAlpha: 0, ease: 'sine.inOut' }, {
            duration: 1, x: 0, autoAlpha: 1,
            y: 0, ease: 'sine.inOut',
            scrollTrigger: {
                trigger: item,
                start: '50% 100%',
                end: '50% 100%',
                scrub: 2,
                markers: false,
            }
        })
    })

    // AbotSection
    // gsap.to('#cloudAbotMove >*', { duration: 1, repeat: -1, yoyo: true, scale: 1.03, transformOrigin: '50% 50%', stagger: { each: 0.05, from: 'random', }, ease: 'sine.inOut' }, "<")
    gsap.to('#handLMan2About', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -10, ease: 'sine.inOut' }, "<")
    gsap.to('#handLAboutWoman', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -5, ease: 'sine.inOut' }, "<")
    gsap.to('#flow', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', y: 5, ease: 'sine.inOut' }, "<")
    gsap.to('#finger01', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', y: 5, ease: 'sine.inOut' }, "<")
    gsap.to('#legAboutL', { duration: 1, repeat: -1, repeatDelay: 2, yoyo: true, transformOrigin: '50% 50%', rotation: -90, ease: 'sine.inOut' }, "<")
    gsap.to('#headAboutWoman', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -10, ease: 'sine.inOut' }, "<")
    gsap.to('#hairAbout', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -10, y: -5, ease: 'sine.inOut' }, "<")
    gsap.to('#leafAbout6', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: 10, ease: 'sine.inOut' }, "<")
    gsap.to('#leafAbout5', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -5, ease: 'sine.inOut' }, "<")
    gsap.to('#handLAboutMan', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: 5, ease: 'sine.inOut' }, "<")
    gsap.to('#handRAboutMan', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -5, ease: 'sine.inOut' }, "<")
    gsap.to('#headAboutMan', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', rotation: -5, ease: 'sine.inOut' }, "<")
    // gsap.to('#leafAbout1', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', scale: 1.05, ease: 'sine.inOut' }, "<")
    // gsap.to('#leafAbout2', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', scale: 1.05, ease: 'sine.inOut' }, "<")
    // gsap.to('#shadowAbout', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', scale: 1.05, ease: 'sine.inOut' }, "<")
    // gsap.to('#treeAbout', { duration: 1, repeat: -1, delay: 0.5, yoyo: true, transformOrigin: '50% 50%', rotation: -2, ease: 'sine.inOut' }, "<")
    gsap.to('#leafAbout3', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', skewX: -3, ease: 'sine.inOut' }, "<")
    gsap.to('#leafAbout4', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', skewX: 3, ease: 'sine.inOut' }, "<")
    gsap.to('#l1', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: 8, ease: 'sine.inOut' }, "<")
    gsap.to('#l2', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '70% 70%', rotation: 18, ease: 'sine.inOut' }, "<")
    gsap.to('#l3', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: 5, ease: 'sine.inOut' }, "<")
    gsap.to('#l4', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: -10, ease: 'sine.inOut' }, "<")
    gsap.to('#l5', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: 10, ease: 'sine.inOut' }, "<")
    gsap.to('#l6', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: -10, ease: 'sine.inOut' }, "<")
    gsap.to('#l7', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 100%', rotation: 10, ease: 'sine.inOut' }, "<")
    gsap.to('#shadowA', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '50% 50%', scale: 1.03, ease: 'sine.inOut' }, "<")
    // let car = gsap.timeline({})
    gsap.to('#car1', { duration: 10, repeat: -1, transformOrigin: '50% 50%', x: -635, y: 375, ease: 'sine.inOut' }, "<")
    gsap.to('#car3', { duration: 15, repeat: -1, transformOrigin: '50% 50%', x: 1200, y: 765, ease: 'sine.inOut' }, "<")
    gsap.to('#car6', { duration: 10, repeat: -1, delay: 0, transformOrigin: '50% 50%', x: 570, y: 350, ease: 'sine.inOut' }, "<")
    gsap.to('#car7', { duration: 10, repeat: -1, repeatDelay: 4, transformOrigin: '50% 50%', x: 250, y: 150, ease: 'sine.inOut' }, "<")
    gsap.to('#car8', { duration: 10, repeat: -1, repeatDelay: 4, transformOrigin: '50% 50%', x: -400, y: 220, ease: 'sine.inOut' }, "<")
    gsap.to('#car9', { duration: 8, repeat: -1, repeatDelay: 4, transformOrigin: '50% 50%', x: 800, y: 530, ease: 'sine.inOut' }, "<")
    gsap.to('#car10', { duration: 8, repeat: -1, delay: 1.5, repeatDelay: 4, transformOrigin: '50% 50%', x: 800, y: 530, ease: 'sine.inOut' }, "<")
    gsap.to('#car11', { duration: 8, repeat: -1, delay: 1.5, repeatDelay: 4, transformOrigin: '50% 50%', x: 800, y: 530, ease: 'sine.inOut' }, "<")
    gsap.to('#car4', { duration: 15, repeat: -1, delay: 1.2, transformOrigin: '50% 50%', x: 670, y: 430, ease: 'sine.inOut' }, "<")
    gsap.to('#car5', { duration: 15, repeat: -1, delay: 1.2, transformOrigin: '50% 50%', x: 670, y: 430, ease: 'sine.inOut' }, "<")
    gsap.to('#car2', { duration: 10, repeat: -1, delay: 2, transformOrigin: '50% 50%', x: -635, y: 375, ease: 'sine.inOut' }, "<")
    // gsap.to('#flag', { duration: 1, repeat: -1, yoyo: true, transformOrigin: '0% 100%', skewY: 10.5, scaleX: 0.8, ease: 'sine.inOut' })
    // gsap.to('#flagShadow', { duration: 1, repeat: -1, yoyo: true, opacity: 0, ease: 'sine.inOut' })
    function option(params) {

        var x, i, j, l, ll, selElmnt, a, b, c;
        /* Look for any elements with the class "custom-select": */
        x = document.getElementsByClassName("custom-select");
        l = x.length;
        for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            ll = selElmnt.length;
            /* For each element, create a new DIV that will act as the selected item: */
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /* For each element, create a new DIV that will contain the option list: */
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < ll; j++) {
                /* For each option in the original select element,
                create a new DIV that will act as an option item: */
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function (e) {
                    /* When an item is clicked, update the original select box,
                    and the selected item: */
                    var y, i, k, s, h, sl, yl;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    sl = s.length;
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML == this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            yl = y.length;
                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class");
                            }
                            this.setAttribute("class", "same-as-selected");
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function (e) {
                /* When the select box is clicked, close any other select boxes,
                and open/close the current select box: */
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });
        }

        function closeAllSelect(elmnt) {
            /* A function that will close all select boxes in the document,
            except the current select box: */
            var x, y, i, xl, yl, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            xl = x.length;
            yl = y.length;
            for (i = 0; i < yl; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }

        /* If the user clicks anywhere outside the select box,
        then close all select boxes: */
        document.addEventListener("click", closeAllSelect);
    }
    option()
    function noRequaredBtn(params) {
        gsap.to('.email-wrong', { duration: 0.05, x: -5, repeat: 10, yoyo: true })
    }
    let studentItem = document.querySelector('.select-items >:nth-child(1)')
    let farmerItem = document.querySelector('.select-items >:nth-child(2)')
    let wasteItem = document.querySelector('.select-items >:nth-child(3)')
    document.addEventListener("DOMContentLoaded", function () {
        var selectElement = document.getElementById("somename");
        selectElement.addEventListener("change", function () {
            var selectedValue = selectElement.options[selectElement.selectedIndex].text;
            document.getElementById("selected-value").value = selectedValue;
        });
    });
    // document.querySelector('.btn-student').addEventListener('click', function () {
    //     setTimeout(() => {

    //         let selectedText = studentItem.textContent;
    //         document.querySelector('.select-selected').textContent = selectedText;
    //         document.querySelector('select[name="somename"]').value = 'Student';
    //         studentItem.classList.add('same-as-selected');
    //     }, 500);
    // });

    // document.querySelector('.btn-farmer').addEventListener('click', function () {
    //     setTimeout(() => {

    //         let selectedText = farmerItem.textContent;
    //         document.querySelector('.select-selected').textContent = selectedText;
    //         document.querySelector('select[name="somename"]').value = 'Farmer';
    //         farmerItem.classList.add('same-as-selected');
    //     }, 500);
    // });

    // document.querySelector('.btn-waste').addEventListener('click', function () {
    //     setTimeout(() => {

    //         let selectedText = wasteItem.textContent;
    //         document.querySelector('.select-selected').textContent = selectedText;
    //         document.querySelector('select[name="somename"]').value = 'Waste Producer';
    //         wasteItem.classList.add('same-as-selected');
    //     }, 500);
    // });
    document.getElementById('emailInput').addEventListener('change', function () {
        var email = this.value.trim();

        var parts = email.split('@');
        var domain = parts[1];

        fetch('/wp-content/themes/carbonzero/assets/base/all_email_provider_domains.txt')
            .then(response => response.text())
            .then(data => {
                var domains = data.split('\n');

                if (!domains.includes(domain)) {
                    gsap.to('.email-wrong', { duration: 0.05, opacity: 1 })
                    noRequaredBtn()
                    gsap.set('.email-wrong', { x: 0 });
                    setTimeout(() => {
                        gsap.to('.email-wrong', { duration: 0.05, opacity: 0 })
                    }, 1000);
                } else {
                    document.querySelector(".button-submit").disabled = false;
                }
            })
    });

    document.getElementById("form-container").addEventListener("submit", function (event) {
        // event.preventDefault();
        // gsap.set('.form', { opacity: 0, pointerEvents: 'none' });
        gsap.set('#form-container', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-submit', { opacity: 1, pointerEvents: 'all' });
        gsap.set('.form-bg', { pointerEvents: 'none' });
        // var formData = new FormData(this);

        // fetch('https://carbonzero.eco/wp-content/themes/carbonzero/assets/mail-company.php', {
        //     method: 'POST',
        //     body: formData
        // })
        //     .then(response => {
        //         if (response.ok) {
        //             window.location.href = 'https://carbonzero.eco/';
        //         }
        //     })

    });

    function noRequared(params) {
        gsap.to('#form-container', { duration: 0.05, x: -5, repeat: 10, yoyo: true })
    }
    document.querySelectorAll('.btn-form').forEach(function (btn) {
        btn.addEventListener('click', function () {
            document.querySelector(".button-submit").disabled = true;
            gsap.set('.form', { opacity: 1, pointerEvents: 'all' });
            gsap.set('#form-container', { opacity: 1, pointerEvents: 'all' });
            gsap.set('.form-bg', { pointerEvents: 'all' });
        });
    });
    const checkbox = document.getElementById('weekday-1');
    document.querySelector('.form-bg').addEventListener('click', function () {
        gsap.set('.form', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-submit', { opacity: 0, pointerEvents: 'none' });
        gsap.set('#form-container', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-bg', { pointerEvents: 'none' });
        studentItem.classList.remove('same-as-selected');
        farmerItem.classList.remove('same-as-selected');
        wasteItem.classList.remove('same-as-selected');
        var elements = document.getElementsByTagName("input");
        for (var ii = 0; ii < elements.length; ii++) {
            if (elements[ii].type == "text" || elements[ii].type == "tel") {
                elements[ii].value = "";
            }
        }
        checkbox.checked = false;

    })
    document.querySelector('.form-close').addEventListener('click', function () {
        gsap.set('.form', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-submit', { opacity: 0, pointerEvents: 'none' });
        gsap.set('#form-container', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-bg', { pointerEvents: 'none' });
        studentItem.classList.remove('same-as-selected');
        farmerItem.classList.remove('same-as-selected');
        wasteItem.classList.remove('same-as-selected');
        var elements = document.getElementsByTagName("input");
        for (var ii = 0; ii < elements.length; ii++) {
            if (elements[ii].type == "text" || elements[ii].type == "tel") {
                elements[ii].value = "";
            }
        }
        checkbox.checked = false;

    })
    document.querySelector('.button-submit').addEventListener('click', function () {
        let inputs = document.getElementsByTagName('input');
        let isEmpty = false;

        for (let i = 0; i < inputs.length; i++) {
            if (!inputs[i].value.trim()) {
                isEmpty = true;
                break;
            }
        }

        if (isEmpty) {
            console.log('required');
            noRequared();
            gsap.set('#form-container', { x: 0 });
        } else {
            console.log('no required');
            gsap.set('#form-container', { opacity: 0 });
        }
    });

    document.querySelector(".form-submit button").addEventListener("click", function () {
        gsap.set('.form', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-submit', { opacity: 0, pointerEvents: 'none' });
        gsap.set('#form-container', { opacity: 0, pointerEvents: 'none' });
        gsap.set('.form-bg', { pointerEvents: 'none' });


        checkbox.checked = false;
        var elements = document.getElementsByTagName("input");
        for (var ii = 0; ii < elements.length; ii++) {
            if (elements[ii].type == "text" || elements[ii].type == "tel") {
                elements[ii].value = "";
            }
        }

    });


})

