document.addEventListener("DOMContentLoaded", function () {
    console.log('mainJS');
    let year = new Date().getFullYear();
    document.getElementById("newYear").innerHTML = year;
    // ========== NAVBAR JS CODE ==========
    function showNav() {
        var navBar = document.getElementById("navBar");
        var body = document.body;
        var navToggleIcon = document.getElementById("navToggleIcon");
        var overlay = document.getElementById("overlay");

        navBar.classList.toggle("show_nav");
        overlay.classList.toggle("show_overlay");
        body.classList.toggle("nav_mobile_scroll");
        navToggleIcon.src = navBar.classList.contains("show_nav")
            ? "./assets/images/svg/cross.svg"
            : "./assets/images/svg/nav-humber.svg";
    }

    //   backtotop
    let mybutton = document.getElementById("back-top");
    mybutton.style.display = "none";
    window.onscroll = function () {
        scrollFunction();
    };
    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            mybutton.style.scale = "1";
            mybutton.style.display = "block";
            mybutton.classList.add("back_top");
        } else {
            mybutton.style.scale = "0";
            mybutton.classList.remove("back_top");
        }
    }
    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // // ================= SWIPER ==========

    // var swiper = new Swiper(".mySwiper", {
    //     pagination: {
    //         el: ".swiper-pagination",
    //         clickable: true,
    //     },
    //     autoplay: {
    //         delay: 2000,
    //         disableOnInteraction: false,
    //     },
    //     // effect: "fade",
    //     cssMode: true,
    //     loop: true,
    //     // loop: false,
    // });

    // ===================
    const sections = document.querySelectorAll(".nav_scroll");
    const navLinks = document.querySelectorAll("nav a");
    let scrollTimeout;

    function setActiveNavLink() {
        clearTimeout(scrollTimeout); // Clear any existing timeout

        // Set a timeout to wait for scrolling to stop
        scrollTimeout = setTimeout(function () {
            let lastSection = null;

            sections.forEach((section) => {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight * 0.5) {
                    lastSection = section;
                }
            });

            navLinks.forEach((link) => {
                if (
                    lastSection &&
                    link.getAttribute("href").substring(1) === lastSection.id
                ) {
                    link.classList.add("active-nav-link");
                } else {
                    link.classList.remove("active-nav-link");
                }
            });
        }); // Adjust the delay (in milliseconds) as needed
    }

    window.addEventListener("scroll", setActiveNavLink);

    // Set active link initially
    setActiveNavLink();

    // =====================
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
            e.preventDefault();
            const sectionID = this.getAttribute("href");
            const section = document.querySelector(sectionID);
            const navHeight = document
                .querySelector("nav")
                .getBoundingClientRect().height;
            const offset = window.pageYOffset || document.documentElement.scrollTop;
            const sectionTop = section.getBoundingClientRect().top + offset;
            window.scrollTo({
                top: sectionTop - navHeight,
                behavior: "smooth",
            });
        });
    });


    // ========== logo slider =============
    // var swiper = new Swiper(".logoSwiper", {
    //     autoplay: {
    //         delay: 2000,
    //         disableOnInteraction: false,
    //     },
    //     effect: "linear",
    //     loop: true,
    //     slidesPerView: 3,
    //     spaceBetween: 30,
    // });
    // var swiper2 = new Swiper('.swiper-container2', {
    //     slidesPerView: 12,
    //     spaceBetween: 290,
    //     speed: 2000,
    //     loop: true,
    //     //allowTouchMove: false, 
    //     autoplay: {
    //         delay: 0,
    //         disableOnInteraction: false 
    //     }
    // });
    //     var swiper = new Swiper('.swiper-container', {
    //         direction: 'horizontal', // Change to 'vertical' if you want vertical sliding
    //         loop: true, // Enable looping of slides
    //         slidesPerView: 'auto', // Number of slides per view (can be 'auto' for responsive)
    //         spaceBetween: 30, // Space between each slide
    //         autoplay: {
    //             delay: 3000, // Delay between slides in milliseconds
    //             disableOnInteraction: false, // Prevent autoplay from stopping when user interacts with slider
    //         },
    //     });
});
